import {useFetchUserApi, useUserLoginApi} from '~/composables/auth/index'
import {useStateCSpotSession} from '~/composables/auth/useStateCSpotSession'
import type {CSPotSession, UserWithoutPassword} from '~/types/user'
import {USER_ROLE_ADMIN, USER_ROLE_PUBLISHER} from '~/constants/profile'

export const getCSpotSession = (): CSPotSession => {
  const stateSession: Ref<CSPotSession> = useStateCSpotSession()
  return stateSession.value
}

export const setCSpotSession = async (data: any): Promise<void> => {
  const stateSession: Ref<CSPotSession> = useStateCSpotSession()

  const {overwrite} = await useSession()

  const {user, access_token, refresh_token, id, createdAt, ip}: CSPotSession = data
  const sessionObject: {
    access_token: string | undefined
    refresh_token: string | undefined
    createdAt: Date
    ip: string | undefined
    id: string
    user: UserWithoutPassword | undefined
    canSeePopup: boolean
  } = {user, access_token, refresh_token, id, createdAt, ip, canSeePopup: true}
  stateSession.value = sessionObject
  overwrite(sessionObject)
}

export const setUserAvatar = async (avatar: string): Promise<void> => {
  let sessionData: CSPotSession = getCSpotSession()
  if (sessionData.user) {
    sessionData.user.avatar = avatar
    await setCSpotSession(sessionData)
  }
}

export const setUserWalletAddress = async (walletAddress: string | null): Promise<void> => {
  let sessionData: CSPotSession = getCSpotSession()
  if (sessionData.user) {
    sessionData.user.walletAddress = walletAddress
    await setCSpotSession(sessionData)
  }
}

export const setUserName = async (username: string): Promise<void> => {
  let sessionData: CSPotSession = getCSpotSession()
  if (sessionData.user) {
    sessionData.user.username = username
    await setCSpotSession(sessionData)
  }
}

export const setHidePopup = async (): Promise<void> => {
  let sessionData: CSPotSession = getCSpotSession()
  if (sessionData.user) {
    sessionData.canSeePopup = false
    await setCSpotSession(sessionData)
  }
}

export const login = async (email: string, password: string, locale, languageCookie) => {
  try {
    const {data: loginData, error} = await useUserLoginApi(email, password)
    if (error && error.value) {
      return {error}
    }

    const {access_token, refresh_token} = loginData.value
    await loadSessionData(access_token, refresh_token, locale, languageCookie)

    return {error: null}
  } catch (e) {
    return {error: e}
  }
}

export const loadSessionData = async (access_token: string, refresh_token: string, locale, languageCookie) => {
  const {data: user} = await useFetchUserApi({accessToken: access_token})

  const unpackedUser = toValue(user)

  const data: {
    access_token: string
    refresh_token: string
    user: UserWithoutPassword
  } = {
    user: unpackedUser,
    access_token: access_token,
    refresh_token: refresh_token
  }
  await setCSpotSession(data)

  // Update preferred language
  if (unpackedUser && unpackedUser.preferredLanguage && unpackedUser.preferredLanguage.key) {
    // Update current language
    locale.value = unpackedUser.preferredLanguage.key

    // Update cookie value
    languageCookie.value = unpackedUser.preferredLanguage.key
  }
}

export const logout = async (): Promise<void> => {
  await resetCSPotSession()
}

export const resetCSPotSession = async (): Promise<void> => {
  await setCSpotSession({
    access_token: null,
    refresh_token: null,
    user: null
  })
}

export const updateSessionData = async (data: {access_token: string; refresh_token: string; user: UserWithoutPassword}) => {
  await setCSpotSession(data)
}

export const userIsPublisher = () => {
  const session: CSPotSession = getCSpotSession()

  if (!session || !session.user || !session.user.roles) {
    return false
  }

  return session.user.roles.includes(USER_ROLE_PUBLISHER)
}

export const userIsAdmin = () => {
  const session: CSPotSession = getCSpotSession()

  if (!session || !session.user || !session.user.roles) {
    return false
  }
  return session.user.roles.includes(USER_ROLE_ADMIN)
}
