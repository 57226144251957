import type {Log} from '~/modules/log/log'

export function useLog(subSpace?: string) {
  const log = useNuxtApp().$log as Log

  if (subSpace && log) {
    return log.space(subSpace)
  }

  return log
}
