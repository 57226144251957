import {GTM_EVENT_TYPE_CLICK} from '~/constants/gtmEvent'

export default defineNuxtPlugin((nuxtapp): void => {
  if (process.server) return

  const gtm = useGtm()

  const eventTracker: {name: string; triggerEvent: (event: string, action?: string) => void} = {
    name: 'eventTracker',
    triggerEvent: function (event: string, action: string = GTM_EVENT_TYPE_CLICK) {
      if (gtm) {
        gtm.trackEvent({
          event,
          action
        })
      }
    }
  }

  return {
    provide: {
      eventTracker
    }
  }
})
