export default defineNuxtPlugin((nuxtapp): void => {
  const config = useRuntimeConfig()

  const getAbsoluteUrl = () => {
    if (process.client) {
      return window.location.origin
    }

    // Use in SSR mode
    return config.public.WEB_BASE_URL
  }

  const generateReferralCode = (referCode: string): string => {
    return `${getAbsoluteUrl()}/landing?referral=${referCode}`
  }

  return {
    provide: {
      getAbsoluteUrl,
      generateReferralCode
    }
  }
})
