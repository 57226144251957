import {useToggleColorMode} from '~/composables/common'

export const COLOR_MODE_LIGHT = 'light'
export const COLOR_MODE_DARK = 'dark'

export default defineNuxtPlugin((nuxtapp): void => {
  const colorMode = useColorMode()

  const toggleDarkTheme = () => {
    if (colorMode.preference === COLOR_MODE_DARK) {
      colorMode.preference = COLOR_MODE_LIGHT
      useToggleColorMode(false)
    } else {
      colorMode.preference = COLOR_MODE_DARK
      useToggleColorMode(true)
    }
  }

  return {
    provide: {
      toggleDarkTheme
    }
  }
})
