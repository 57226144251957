
import * as ipxRuntime$KAAfyViRJY from '/app/node_modules/.pnpm/@nuxt+image@1.4.0_rollup@4.12.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "cspot-be.s3.eu-north-1.amazonaws.com",
    "cspot-be-staging.s3.eu-north-1.amazonaws.com",
    "localhost:3000",
    "staging.cardanospot.io",
    "cardanospot.io"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$KAAfyViRJY, defaults: {} }
}
        