export default defineNuxtPlugin(({vueApp}) => {
  const scrollToTop = () => {
    window?.scrollTo({top: 0, behavior: 'smooth'})
  }

  return {
    provide: {
      scrollToTop
    }
  }
})
