export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"},{"charset":"utf-8"},{"name":"version","content":"96201cb4b80aff8c41d2a64d131964e345c8dc27"}],"link":[{"rel":"stylesheet","href":"https://fonts.cdnfonts.com/css/signpainter","prefetch":"true"},{"rel":"preconnect","href":"fonts.cdnfonts.com/css/signpainter","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.cdnfonts.com/css/montserrat","prefetch":"true"},{"rel":"preconnect","href":"fonts.cdnfonts.com/css/montserrat","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.cdnfonts.com/css/roboto","prefetch":"true"},{"rel":"preconnect","href":"fonts.cdnfonts.com/css/roboto","crossorigin":""}],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0","title":"CardanoSpot","base":{"href":"/"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false