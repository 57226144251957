import {unwrap} from '~/util/async'
import type {FetchRequestConfig} from '~/types/fetch'
import type {H3Event$Fetch} from 'nitropack'

type ServerUrl = 'resource' | 'auth' | 'self' | 'external'

export const getServerUrl = ((): () => string => {
  let serverUrl: string | null = null

  return (): string => {
    if (!serverUrl) {
      const config = useRuntimeConfig()
      serverUrl = config.public.SERVER_URL
    }
    return serverUrl
  }
})()

export const getAuthServerUrl = ((): () => string => {
  let authServerUrl: string

  return (): string => {
    if (!authServerUrl) {
      const config = useRuntimeConfig()

      authServerUrl = config.public.AUTH_SERVER_URL
    }
    return authServerUrl
  }
})()

export const compileAbsoluteRequestUrl = (prefix: string, url: FetchRequestConfig['url']) => {
  if (isRef(url)) {
    return toRef(`${prefix}/${unref(url)}`)
  }

  switch (typeof url) {
    case 'string':
      return `${prefix}/${url}`
    case 'function':
      return () => `${prefix}/${url()}`
    default:
      return `${prefix}/${url}`
  }
}

export const getAbsoluteUrl = ((): () => string => {
  let absoluteUrl: string

  return (): string => {
    if (!absoluteUrl) {
      const config = useRuntimeConfig()

      absoluteUrl = config.public.WEB_BASE_URL
    }
    return absoluteUrl
  }
})()

const compileRequestUrl = (url: string, server: ServerUrl) => {
  switch (server) {
    case 'auth':
      return `${getAuthServerUrl()}/${url}`;
    case 'resource':
      return `${getServerUrl()}/${url}`;
    case 'self':
      return `${getAbsoluteUrl()}/${url}`;
    case 'external':
      return url;
  }
}

/**
 * This method can be used in modules or server middleware etc., where nuxt context does not exist yet
 *
 * @param fetch
 * @param config
 */
export const callExternalApi = async <TResult>(fetch: H3Event$Fetch | undefined | null, config: Partial<Pick<FetchRequestConfig, 'method' | 'url' | 'body' | 'headers' | 'query'>> & {server: ServerUrl}) => {
  const preferredFetch = fetch ?? $fetch

  const {
    method,
    url,
    body,
    headers,
    query,
    server
  } = config

  const data = await preferredFetch((compileRequestUrl(url ?? '/', server)), {
    method,
    ...(body ? { body: {payload: toValue(body) }} : {}),
    ...(headers && Object.keys(headers).length !== 0 ? {headers} : {}),
    ...(query ? { query: toValue(query) } : {}),
  })

  return (await unwrap(data)) as TResult
}
