import {Ref} from 'vue'
import {useToast} from 'tailvue'
import {INTERNAL_EVENT_USER_NOT_REGISTERED} from '~/constants/internal'

export default defineNuxtPlugin((nuxtapp): void => {
  const showErrorToast = (error: Ref<Error | null> | string) => {
    if (process.server) return

    const $toast = useToast()
    const {$eventEmitter} = useNuxtApp()

    let showToast = true
    let message = ''
    if (typeof error === 'string') {
      message = error
    } else {
      if (error.value?.statusCode === 401) {
        showToast = false
        // Emit a global event to show a popup to let the user register
        $eventEmitter(INTERNAL_EVENT_USER_NOT_REGISTERED)
      }
      const errorMessage = error?.value?.data?.message
      message = typeof errorMessage === 'string' ? errorMessage : 'There was an error'
    }
    if (showToast) {
      $toast.show({
        type: 'danger',
        message
      })
    }
  }

  const showSuccessToast = (message: string) => {
    if (process.server) return

    const $toast = useToast()

    $toast.show(message)
  }

  return {
    provide: {
      showErrorToast,
      showSuccessToast
    }
  }
})
