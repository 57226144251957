// Event types
export const GTM_EVENT_TYPE_CLICK = 'click'
export const GTM_EVENT_TYPE_SUBMIT = 'form submit'
export const GTM_EVENT_TYPE_LINK = 'link'

// User events
export const GTM_EVENT_LOGIN = 'event_sent_sign_in_web'
export const GTM_EVENT_RESET_PASSWORD = 'event_sent_reset_password_web'
export const GTM_EVENT_EMAIL_REGISTRATION = 'event_sent_registration_with_email_web'
export const GTM_EVENT_SSO_GITHUB_REGISTRATION = 'event_sent_registration_with_GitHub_web'
export const GTM_EVENT_SSO_TWITTER_REGISTRATION = 'event_sent_registration_with_Twitter_web'
export const GTM_EVENT_SSO_APPLE_REGISTRATION = 'event_sent_registration_with_Apple_web'
export const GTM_EVENT_SSO_WALLET_REGISTRATION = 'registration_with_Wallet_event_web'
export const GTM_EVENT_EMAIL_VERIFICATION = 'event_sent_email_verification_web'
export const GTM_EVENT_FINISHED_ONBOARDING = 'event_sent_finished_onboarding_web'
export const GTM_EVENT_CREATE_POST = 'posting_in_hub_event_web'
export const GTM_EVENT_UPVOTE_POST = 'upvote_in_hub_event_web'
export const GTM_EVENT_POST_COMMENT_CREATED = 'comment_in_hub_event_web'
export const GTM_EVENT_POST_SHARE_TWITTER = 'post_share_in_hub_Tw_event_web'
export const GTM_EVENT_POST_SHARE_FACEBOOK = 'post_share_in_hub_Fb_event_web'
export const GTM_EVENT_JOIN_COMMUNITY = 'join_community_event_web'
export const GTM_EVENT_FOLLOW_USER = 'follow_user_event_web'
export const GTM_EVENT_LIKE_POST_COMMENT = 'like_of_post_comment_web'
export const GTM_EVENT_LIKE_ARTICLE_COMMENT = 'like_of_article_comment_web'
export const GTM_EVENT_SUBMIT_ARTICLE = 'article_submission_event_web'
export const GTM_EVENT_LIKE_ARTICLE = 'like_article_event_web'
export const GTM_EVENT_ARTICLE_COMMENT_CREATED = 'comment_article_event_web'
export const GTM_EVENT_ARTICLE_SHARE_TWITTER = 'article_share_Tw_event_web'
export const GTM_EVENT_ARTICLE_SHARE_FACEBOOK = 'article_share_Fb_event_web'
export const GTM_EVENT_SUMMIT_FLOATING_BUTTON_CLICK = 'floating_button_click_event_web'
export const GTM_EVENT_DISCOVER_PROJECT = 'event_sent_cont_disc_project_web'
export const GTM_EVENT_DISCOVER_EVENT = 'event_sent_cont_disc_event_web'
export const GTM_EVENT_DISCOVER_ARTICLE = 'event_sent_cont_disc_news_web'
export const GTM_EVENT_SUBMIT_PROJECT = 'project_submit_event_sent_web'
export const GTM_EVENT_SUBMIT_TOKEN = 'coin_submit_event_sent_web'

// Guest user events
export const GTM_EVENT_GUEST_MODAL_REGISTER_EMAIL = 'modal_window_registration_email_event_web'
export const GTM_EVENT_GUEST_MODAL_REGISTER_TWITTER = 'modal_window_registration_twitter_event_web'
export const GTM_EVENT_GUEST_MODAL_REGISTER_GITHUB = 'modal_window_registration_github_event_web'
export const GTM_EVENT_GUEST_MODAL_REGISTER_APPLE = 'modal_window_registration_Apple_event_web'
export const GTM_EVENT_GUEST_MODAL_REGISTER_WALLET = 'modal_window_registration_Wallet_event_web'

export const GTM_EVENT_GUEST_MODAL_LOGIN_EMAIL = 'modal_window_login_email_event_web'
export const GTM_EVENT_GUEST_MODAL_LOGIN_TWITTER = 'modal_window_login_twitter_event_web'
export const GTM_EVENT_GUEST_MODAL_LOGIN_GITHUB = 'modal_window_login_github_event_web'
export const GTM_EVENT_GUEST_MODAL_LOGIN_APPLE = 'modal_window_login_Apple_event_web'
export const GTM_EVENT_GUEST_MODAL_LOGIN_WALLET = 'modal_window_login_Wallet_event_web'

export const GTM_EVENT_GUEST_USER_PROFILE = 'open_access_user_profile_event_web'
export const GTM_EVENT_GUEST_SUBMIT_ARTICLE_FOOTER = 'open_access_submit_article_footer_event_web'
export const GTM_EVENT_GUEST_SUBMIT_EVENT_FOOTER = 'open_access_submit_event_footer_web'

export const GTM_EVENT_GUEST_SUBMIT_ARTICLE_NEWS = 'open_access_submit_article_newsfeed_event_web'
export const GTM_EVENT_GUEST_LIKE_ARTICLE = 'open_access_like_article_event_web'
export const GTM_EVENT_GUEST_LIKE_ARTICLE_COMMENT = 'open_access_like_article_comment_event_web'
export const GTM_EVENT_GUEST_ARTICLE_COMMENTS = 'open_access_comment_article_event_web'
export const GTM_EVENT_GUEST_FOLLOW_USER_NEWS = 'open_access_follow_user_newsfeed_event_web'

export const GTM_EVENT_GUEST_ADD_EVENT_EXPLORE = 'open_access_add_event_to_calendar_exp_web'

export const GTM_EVENT_GUEST_LIKE_PROJECT = 'open_access_like_project_event_web'

export const GTM_EVENT_GUEST_ADD_EVENT_EVENTS = 'open_access_add_event_to_calendar_events_web'
export const GTM_EVENT_GUEST_SUBMIT_EVENT = 'open_access_submit_event_events_web'

// Guest user Livestream page
export const GTM_EVENT_GUEST_ACCESS_LIVESTREAM_LIKE = 'open_access_livestream_like_event_web'
export const GTM_EVENT_GUEST_ACCESS_LIVESTREAM_POST = 'open_access_livestream_post_event_web'
export const GTM_EVENT_GUEST_ACCESS_LIVESTREAM_VIEW_PROFILE = 'open_access_livestream_user_profile_event_web'

// Guest user Community Hub
export const GTM_EVENT_GUEST_ACCESS_FOLLOWING_FEED = 'open_access_following_feed_event_web'
export const GTM_EVENT_GUEST_UPVOTE_POST = 'open_access_upvote_hub_event_web'
export const GTM_EVENT_GUEST_CREATE_POST = 'open_access_posting_hub_event_web'
export const GTM_EVENT_GUEST_LIKE_POST_COMMENT = 'open_access_like_comment_hub_event_web'
export const GTM_EVENT_GUEST_CREATE_COMMENT = 'open_access_commenting_hub_event_web'
export const GTM_EVENT_GUEST_BOOKMARK_POST = 'open_access_bookmark_hub_event_web'
export const GTM_EVENT_GUEST_JOIN_COMMUNITY = 'open_access_join_community_hub_event_web'
export const GTM_EVENT_GUEST_FOLLOW_USER_HUB = 'open_access_follow_user_hub_event_web'

// Onboarding
export const GTM_EVENT_USER_ONBOARD_GOAL = 'onboard_goal_event_sent_web'
export const GTM_EVENT_USER_ONBOARD_SELECT_EVENT = 'onboard_post_select_event_sent_web'
export const GTM_EVENT_USER_ONBOARD_POSTED = 'onboard_posted_event_sent_web'
