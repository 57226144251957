import type {FloatingButtonData} from '~/types/common'
import {useFetchResource} from '~/composables/fetch/useFetchResource'

const API_VERSION = 'v2'

const COMMON_ENDPOINT_ROOT = `common/${API_VERSION}`

export const useGetBookmarks = async (id: string) => {
  return useFetchResource<any>({
    url: `${COMMON_ENDPOINT_ROOT}/user-bookmarks`,
    method: 'get'
  })
}

export const useBookmarkItem = async (id: string, type: string) => {
  return useFetchResource<any>({
    url: `${COMMON_ENDPOINT_ROOT}/bookmark`,
    method: 'post',
    body: {id, type}
  })
}

export const useGetIsFloatingButton = async () => {
  return useFetchResource<FloatingButtonData>({
    url: `${COMMON_ENDPOINT_ROOT}/floating-action-button`,
    method: 'get'
  })
}

export const useUpdateIsFloatingButton = async (actionUrl: string | null, imgSrc: string | null) => {
  return useFetchResource<any>({
    url: `${COMMON_ENDPOINT_ROOT}/update-floating-action-button`,
    method: 'post',
    body: {imgSrc, actionUrl}
  })
}

export const useGetXmasMode = async () => {
  return useFetchResource<any>({
    url: `${COMMON_ENDPOINT_ROOT}/is-xmas`,
    method: 'get'
  })
}

export const useUpdateXmasMode = async (xmasMode: boolean) => {
  return useFetchResource<any>({
    url: `${COMMON_ENDPOINT_ROOT}/update-xmas`,
    method: 'post',
    body: {xmasMode}
  })
}

export const useIsXmasInSession = (xmasValue: boolean = false) => {
  return useState<boolean>('xmas', (): boolean => xmasValue)
}

export const useIsXmasMode = () => {
  return useState<boolean>('xmas')
}

export const useIsDarkMode = () => {
  return useState<boolean>('isDarkMode')
}

export const useToggleColorMode = (isDark: boolean = false) => {
  const isDarkMode = useIsDarkMode()
  isDarkMode.value = isDark
}
