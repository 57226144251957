import {datadogRum} from '@datadog/browser-rum'
import {cleanErrorData, enrichErrorData, getDatadogEnvironment} from '~/modules/log/util'
import type {LogEntry, MonitorHandler} from '~/types/log'
import {useStateCSpotSession} from '~/composables/auth/useStateCSpotSession'
import type {CSPotSession} from '~/types/user'
import type {Ref} from 'vue'

export const initDatadogRum = () => {
  const $config = useRuntimeConfig()
  const route = useRoute()

  if (!$config.public.C_SPOT_LOG.DD_RUM_TOKEN || !$config.public.C_SPOT_LOG.DD_RUM_APP_ID) {
    return
  }

  const getSessionSampleRate = () => {
    return $config.public.IS_PRODUCTION ? +$config.public.C_SPOT_LOG.DD_RUM_SESSIONS_RECORDED : 100
  }

  datadogRum.init({
    applicationId: $config.public.C_SPOT_LOG.DD_RUM_APP_ID,
    clientToken: $config.public.C_SPOT_LOG.DD_RUM_TOKEN,
    site: 'datadoghq.com',
    service: 'cspot-frontend-web',
    env: getDatadogEnvironment($config.public.CURRENT_ENV),
    version: $config.VERSION,
    sessionSampleRate: getSessionSampleRate(),
    sessionReplaySampleRate: getSessionSampleRate(), // start recording it if we wanna know a user journey, actually records a screen and stores data
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input'
    // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#override-default-rum-view-names
    // trackViewsManually: true
  })

  extendRumWithUser()

  datadogRum.startView({
    name: route.meta.name,
    service: 'cspot-frontend-web',
    version: $config.VERSION
  })

  // datadogRum.startSessionReplayRecording(); we do not replay sessions, too expensive for now
}

/**
 * Add user to the RUM session in case we have it in context
 * this means plugin is initiated when user is logged in.
 */
export const extendRumWithUser = () => {
  const stateSession: Ref<CSPotSession> = useStateCSpotSession()

  if (stateSession.value !== null) {
    datadogRum.setUser({
      id: String(stateSession.value.user?.id),
      username: stateSession.value.user?.username,
      referCode: stateSession.value.user?.referCode,
      email: stateSession.value.user?.email
    })
  }
}

export const applyDatadogRum = (entry: LogEntry) => {
  const $config = useRuntimeConfig()

  if (!process.client) {
    return
  }

  const session = useStateCSpotSession()

  if (!$config.public.C_SPOT_LOG.DD_RUM_TOKEN || !$config.public.C_SPOT_LOG.DD_RUM_APP_ID) {
    return
  }

  // just log errors to datadog
  // DD defines on its own whether it's a network or custom error
  if (['debug', 'info', 'warn'].includes(entry.level)) {
    return
  }

  datadogRum.addError(entry.message, enrichErrorData(session, cleanErrorData(entry.data)))
}

// datadogRum.addAction
export const trackCustomEvent: MonitorHandler = (eventName, eventOptions) => {
  const $config = useRuntimeConfig()
  if (!$config.public.C_SPOT_LOG.DD_RUM_TOKEN || !$config.public.C_SPOT_LOG.DD_RUM_APP_ID) {
    return
  }

  try {
    datadogRum.addAction(eventName, eventOptions)
  } catch (e) {
    console.error('datadog rum add action failed', e)
  }
}

export const setCustomAttribute = (attributeName: string, attributeValue: string | number) => {
  if (!process.client) {
    return
  }

  datadogRum.setUserProperty(attributeName, attributeValue)
}
