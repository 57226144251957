export default defineNuxtPlugin(({vueApp}) => {
  const config = useRuntimeConfig()

  const DEFAULT_IMAGE = config.public.WEB_BASE_URL + '/seo/cardanospot.jpg'
  const TITLE_PREFIX = 'Cardano Spot | '

  const setPageSeo = ({title, description, image = DEFAULT_IMAGE}) => {
    useSeoMeta({
      title: TITLE_PREFIX + title,
      ogTitle: TITLE_PREFIX + title,
      description: description,
      ogDescription: description,
      ogImage: image,
      twitterCard: 'summary_large_image',
      twitterTitle: TITLE_PREFIX + title,
      twitterDescription: description,
      twitterImage: image
    })
  }

  return {
    provide: {
      setPageSeo
    }
  }
})
