import type {CSPotSession} from '~/types/user'
import {getCSpotSession} from '~/composables/auth/useAuth'
import {getServerUrl} from '~/api'
import {io} from 'socket.io-client'

export default defineNuxtPlugin((nuxtapp): void => {
  if (process.server) return

  let socket = new CSSocket()

  if (process.client) {
    window.onbeforeunload = function () {
      console.log('Websocket disconnected')
      socket.disconnect()
    }
  }

  return {
    provide: {
      socket
    }
  }
})

class CSSocket {
  constructor() {
    this.connection = null
  }

  isConnected() {
    return this.connection != null
  }

  connect() {
    try {
      const session: CSPotSession = getCSpotSession()
      const {access_token} = session

      this.connection = io(getServerUrl(), {
        auth: {
          token: access_token
        },
        transports: ['websocket'],
        secure: true,
      })
      console.log('Websocket connected')
    } catch (e) {
      console.log(e)
    }
  }

  disconnect = () => {
    if (this.connection) {
      this.connection.disconnect()
    }
  }

  getInstance() {
    if (!this.isConnected()) {
      this.connect()
    }
    return this.connection
  }
}
