import {H3Event} from 'h3'
import type {CSPotSession} from '~/types/user'
import type {Ref} from 'vue'
import type {LogEntry} from '~/types/log'

export const getDatadogEnvironment = (currentEnv: string) => {
  switch (currentEnv) {
    case 'production':
      return 'cspot-prod';
    case 'staging':
      return 'cspot-staging'
    default:
      return 'local'
  }
}

export const isPageRequest = (event: H3Event): boolean => {
  const url = event.path

  if (!url) {
    return false
  }

  return !['/api', '/_nuxt', '/__nuxt', '/_/'].some((urlStart) => url.startsWith(urlStart))
}

export const enrichErrorData = (session: Ref<CSPotSession>, data: LogEntry['data']) => {
  if (!data) {
    return data;
  }

  let dataClone: object = {...data}

  if (session.value !== null) {
    dataClone = {
      ...dataClone,
      userId: session.value.user?.id
    }
  }

  return dataClone;
}

export const cleanErrorData = (data: LogEntry['data']): LogEntry['data'] => {
  if (!data) {
    return data;
  }

  const dataClone = {...data}

  if ('vm' in dataClone) {
    // @ts-ignore
    delete dataClone.vm;
  }

  return dataClone;
}
