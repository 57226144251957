import type {
  ConsolaInstance,
  ConsolaOptions,
  LogLevel as ConsolaLevel,
} from 'consola'
import type { LogLevel, LogEntry } from '~/types/log'
import { Log } from './log'
import {applyDatadogRum} from '~/modules/log/runtime/loggers/datadog/datadog-rum'

function prepareData(data: any) {
  if (!data) {
    return data
  }
  if (data.response) {
    const { response, message, name, code, ...other } = data
    return {
      message,
      name,
      code,
      status: response?.status,
      statusText: response?.statusText,
      ...other,
    }
  }
  return data
}

const levelMap: Record<LogLevel, ConsolaLevel> = {
  error: 0,
  warn: 1,
  info: 3,
  debug: 4,
}

/**
 * Convert our log level strings to consola number codes
 * @see https://github.com/unjs/consola#log-level
 * @param level
 */
function toConsolaLogLevel(level: LogLevel): ConsolaLevel {
  return levelMap[level]
}

export default function createLog(
  createConsola: (options?: Partial<ConsolaOptions>) => ConsolaInstance,
  space = 'cspot',
  level: LogLevel,
): Log {
  const logger = createConsola({
    level: toConsolaLogLevel(level),
  })

  const handler = (entry: LogEntry) => {
    const data = prepareData(entry.data)
    const log = logger[entry.level] || logger.info
    const message = `[${entry.space}] ${entry.message}`
    const args = data ? [message, data] : [message]

    log.apply(log, args as any)

    applyDatadogRum(entry)
  }

  return new Log({
    space,
    handler,
  })
}
