import {LANGUAGE_COOKIE} from '~/constants/i18n'

// This plugin it's used only to set lang attr to html tag
export default defineNuxtPlugin(({vueApp}) => {
  let defaultLocale = 'en'

  const languageCookie = useCookie(LANGUAGE_COOKIE)

  // Init language if you have a cookie
  if (languageCookie.value) {
    defaultLocale = languageCookie.value
  }

  const setI18nLanguage = (languageKey: string) => {
    // This is a suggested solution extracted from the library we use
    // https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
    document?.querySelector('html')?.setAttribute('lang', languageKey)
  }

  // Set HTML attribute on client
  if (process.client) {
    setI18nLanguage(defaultLocale)
  }

  return {
    provide: {
      setI18nLanguage
    }
  }
})
