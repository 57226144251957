import type {NuxtApp} from '#app'
import {Log} from '~/modules/log/log'
import type {FetchRequestConfig} from '~/types/fetch'

function getFetch(nuxtApp: NuxtApp, log: Log): typeof fetch {
  const fetch = nuxtApp.ssrContext?.event.$fetch ?? $fetch

  // https://github.com/unjs/nitro/issues/1848
  if (nuxtApp.ssrContext?.event && !nuxtApp.ssrContext?.event.$fetch) {
    log.error('event.$fetch was not found!')
  }
  return fetch
}

export const useFetchRequest = async <TResult>(config: FetchRequestConfig) => {
  const log = useLog('fetchRequest')

  const {method, url, body, headers, query, watchableParams, isLazy, isOnlyClient, immediate} = config

  const CACHE_KEY = `data-${method}-${typeof url === 'function' ? url() : toValue(url)}`

  log.debug(`useFetchRequest: ${CACHE_KEY}`)

  const {data, pending, error, status, refresh} = await useFetch<TResult>(url, {
    // key: CACHE_KEY,
    method,
    ...(body ? {body: body} : {}),
    ...(headers && Object.keys(headers).length !== 0 ? {headers} : {}),
    ...(query ? {query} : {}),
    ...(watchableParams && query ? {watch: watchableParams} : {}),
    ...(isLazy ? {isLazy: true} : {}),
    ...(isOnlyClient ? {server: false} : {}),
    ...(immediate !== undefined ? {immediate} : {})
  })

  // const { data, pending, error, status, refresh } = await useAsyncData<TResult>(
  //   CACHE_KEY, // a unique key that is used to create a useState data cache internally
  //   () => getFetch(nuxtApp, log)(url, {
  //     method,
  //     ...(body ? { body: toValue(body) } : {}),
  //     ...(headers && Object.keys(headers).length !== 0 ? {headers} : {}),
  //     ...(query ? { query: toValue(query) } : {}),
  //   }), {
  //     ...(watchableParams && query ? { watch: watchableParams} : {}),
  //     ...(isLazy ? {isLazy: true} : {}),
  //     ...(isOnlyClient ? {server: false} : {}),
  //     ...(immediate !== undefined ? {immediate} : {}),
  //   }
  // )

  return {
    data,
    pending,
    refresh,
    error,
    status
  }
}
