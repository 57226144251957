import type {CSPotSession} from '~/types/user'

export const useStateCSpotSession = (defaultValue: CSPotSession = {} as CSPotSession) => {
  return useState<CSPotSession>('user', (): CSPotSession => defaultValue)
}

export const useIsUserInSession = () => {
  const session: Ref<CSPotSession> = useStateCSpotSession()
  return computed(() => !!session.value?.user)
}
