import {LANGUAGE_COOKIE} from '~/constants/i18n'

// Data formatter plugin
export default defineNuxtPlugin(({vueApp}) => {
  const languageCookie = useCookie(LANGUAGE_COOKIE)

  const formatDatesAsString = (dateFrom: string | null, dateTo: string | null) => {
    const language = languageCookie.value || 'en'
    const formatter = new Intl.DateTimeFormat(language, {month: 'short'})
    if (dateFrom === null && dateTo === null) {
      return '-'
    }

    const dateFromDate = new Date(dateFrom as string)
    const dateToDate = new Date(dateTo as string)
    const dayFrom = dateFromDate.getDate()
    const dayTo = dateToDate.getDate()
    const monthFrom = formatter.format(dateFromDate)
    const monthTo = formatter.format(dateToDate)
    const yearFrom = dateFromDate.getFullYear()
    const yearTo = dateToDate.getFullYear()
    if (dateFrom !== null && dateTo === null) {
      return `${dayFrom} ${monthFrom} ${yearFrom}`
    } else if (dayFrom === dayTo && monthFrom === monthTo && yearFrom === yearTo) {
      return `${dayFrom} ${monthFrom} ${yearFrom}`
    } else if (monthFrom === monthTo && yearFrom === yearTo) {
      return `${dayFrom}-${dayTo} ${monthFrom} ${yearFrom}`
    } else {
      return `${dayFrom} ${monthFrom} ${yearFrom} - ${dayTo} ${monthTo} ${yearTo}`
    }
  }

  return {
    provide: {
      formatDatesAsString
    }
  }
})
