import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

export default defineNuxtPlugin((nuxtapp): void => {
  VueMarkdownEditor.use(vuepressTheme, {
    Prism
  })
  VueMarkdownEditor.lang.use('en-US', enUS)

  nuxtapp.vueApp.component('VueMarkdownEditor', VueMarkdownEditor)
})
