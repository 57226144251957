import type {MonitorContext} from '~/types/log'
import {logCriticalEvents} from '~/modules/log/runtime/loggers/datadog/datadog-logs'
import {setCustomAttribute, trackCustomEvent} from '~/modules/log/runtime/loggers/datadog/datadog-rum'

/**
 * This plugin assumes that both Datadog LOGS and Datadog RUM ha been initiated before.
 */
export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      monitor: {
        // critical events are usually tracked more often
        criticalEvent: (eventName, eventOptions) => logCriticalEvents(eventName, eventOptions),
        // custom events are tracked using RUM which is less often
        customAction: (eventName, eventOptions) => trackCustomEvent(eventName, eventOptions),
        // set custom attribute to the user tracked using RUM, so it is possible later to filter users by specific attribute name
        setCustomAttribute: (attributeName, attributeValue) => setCustomAttribute(attributeName, attributeValue)
      } as MonitorContext
    }
  }
})
