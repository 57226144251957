import {useFetchRequest} from '~/composables/fetch/useFetchRequest'
import {compileAbsoluteRequestUrl, getAuthServerUrl} from '~/api'
import {createBasicAuthorizationHeader} from '~/composables/auth'
import type {FetchRequestConfig} from '~/types/fetch'

export const useFetchAuthBasic = async <TResult>(config: FetchRequestConfig) => {
  const headers = createBasicAuthorizationHeader()

  return useFetchRequest<TResult>({
    ...config,
    url: compileAbsoluteRequestUrl(getAuthServerUrl(), config.url),
    ...(Object.keys(config.headers ?? {}).length !== 0 ? {headers: {...config.headers, ...headers}} : {headers}),
  });
}

