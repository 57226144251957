import {useFetchRequest} from '~/composables/fetch/useFetchRequest'
import {compileAbsoluteRequestUrl, getServerUrl} from '~/api'
import type {Ref} from 'vue'
import type {CSPotSession} from '~/types/user'
import {useStateCSpotSession} from '~/composables/auth/useStateCSpotSession'
import type {FetchRequestConfig} from '~/types/fetch'

export const useFetchResource = async <TResult>(config: FetchRequestConfig) => {
  const stateSession: Ref<CSPotSession> = useStateCSpotSession()

  if (!config.isPublic && stateSession.value) {
    const {access_token, user} = toValue(stateSession)
    const preferredLanguage = user?.preferredLanguage?.key || 'en'

    let headers: FetchRequestConfig['headers'] = {'Accept-Language': preferredLanguage}

    if (access_token) {
      headers.authorization = `Bearer ${access_token}`
    }

    return useFetchRequest<TResult>({
      ...config,
      url: compileAbsoluteRequestUrl(getServerUrl(), config.url),
      ...(Object.keys(config.headers ?? {}).length !== 0 ? {headers: {...config.headers, ...headers}} : {headers}),
    });
  }

  return useFetchRequest<TResult>({
    ...config,
    url: compileAbsoluteRequestUrl(getServerUrl(), config.url)
  });
}
