import {datadogLogs} from '@datadog/browser-logs'
import type {MonitorHandler} from '~/types/log'
import {getDatadogEnvironment} from '~/modules/log/util'

export const initDatadogLogs = () => {
  const $config = useRuntimeConfig()

  if (!$config.public.C_SPOT_LOG.DD_LOGS_TOKEN) {
    return
  }

  datadogLogs.init({
    clientToken: $config.public.C_SPOT_LOG.DD_LOGS_TOKEN,
    site: 'datadoghq.com',
    service: 'cspot-frontend-web',
    env: getDatadogEnvironment($config.public.CURRENT_ENV),
    version: $config.public.VERSION,
    sessionSampleRate: $config.public.IS_PRODUCTION ? +$config.public.C_SPOT_LOG.DD_LOGS_SESSIONS_RECORDED : 100,
    forwardErrorsToLogs: false // set to 'false' to stop forwarding console.error logs, uncaught exceptions and network errors to Datadog.
  })
}

export const logCriticalEvents: MonitorHandler = (eventName, eventOptions) => {
  const $config = useRuntimeConfig()

  if (!$config.public.C_SPOT_LOG.DD_LOGS_TOKEN) {
    return
  }

  try {
    datadogLogs.logger?.log?.(eventName, {
      ...eventOptions,
      action: eventName
    })
  } catch (e) {
    console.error('datadog logs sending failed', e)
  }
}
