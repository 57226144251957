import type {RuntimeConfig} from 'nuxt/schema'
import type {UserWithoutPassword} from '~/types/user'
import {
  USER_REGISTRATION_DEVICE_DESKTOP,
  USER_REGISTRATION_DEVICE_MOBILE,
  USER_REGISTRATION_DEVICE_TABLET
} from '~/constants/user'
import {useFetchAuthBasic} from '~/composables/fetch/useFetchAuthBasic'
import {useFetchAuthNoHeaders} from '~/composables/fetch/useFetchAuthNoHeaders'
import {useFetchResource} from '~/composables/fetch/useFetchResource'
import {getAbsoluteUrl} from '~/api'

export const useUserLoginApi = async (
  email: string,
  password: string
) => {
  return useFetchAuthBasic<{
    access_token: string
    refresh_token: string
    token_type: string
  }>({
    url: 'auth/login',
    method: 'post',
    body: {
      email,
      password,
      grant_type: 'password'
    }
  })
}

export const useOauthRevokeTokenApi = async (accessToken: string) => {
  return useFetchAuthBasic({
    url: () => `oauth/tokens/${accessToken}`,
    method: 'delete',
  })
}

export const useRefreshAccessTokenApi = async (refresh_token: string) => {
  return useFetchAuthBasic<void>({
    url: `oauth/token`,
    method: 'post',
    body: {refresh_token},
    headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
  })
}

export const useValidateAccessTokenApi = async (options: {accessToken: string}) => {
  return useFetchAuthNoHeaders<void>({
    url: `oauth/token/validate`,
    method: 'get',
    headers: createAuthorizationHeader(options.accessToken)
  })
}

export const useFetchUserApi = async (options: {accessToken: string}) => {
  return useFetchResource<UserWithoutPassword>({
    url: `user`,
    method: 'get',
    headers: createAuthorizationHeader(options.accessToken),
    isPublic: true // is sent to not try to apply access token in headers and use this one passed from in here
  })
}

export const useUserRegister = async (email: string, referedCode: string | null) => {
  const completeUrl = getAbsoluteUrl() + '/user/new-user/{hash}'

  // If you come through a referral link there should be a referral code
  const {session} = await useSession()

  // Set device type
  const {isMobile, isTablet} = useDevice()
  const regDeviceType = isMobile
    ? USER_REGISTRATION_DEVICE_MOBILE
    : isTablet
    ? USER_REGISTRATION_DEVICE_TABLET
    : USER_REGISTRATION_DEVICE_DESKTOP


  return useFetchAuthBasic({
    url: `auth/register`,
    method: 'post',
    body: {
      email,
      referedCode: referedCode || session.value?.referral,
      completeUrl,
      regDeviceType
    }
  })
}

export const useUserRegisterComplete = async (
  hash: string,
  password: string,
  username: string,
  categories: string[],
  communityRoles: string[],
  avatar: string
) => {
  return useFetchAuthBasic({
    url: `auth/register/complete`,
    method: 'post',
    body: {
      grant_type: 'password',
      hash,
      password,
      username,
      categories,
      communityRoles,
      avatar
    }
  })
}

export const useUserGetInfoFromHash = async (hash: string) => {
  return useFetchAuthBasic({
    url: () => `auth/guest/user/${hash}`,
    method: 'get'
  })
}

export const useUserGetTokenFromHash = async (hash: string) => {
  return useFetchAuthBasic({
    url: () => `auth/guest/token/${hash}`,
    method: 'get'
  })
}

export const useUserForgotPassword = async (email: string) => {
  const resetUrl = getAbsoluteUrl() + '/user/reset/{hash}'
  const completeUrl = getAbsoluteUrl() + '/user/new-user/{hash}'

  return useFetchAuthBasic({
    url: `auth/password/reset-email`,
    method: 'post',
    body: {
      email,
      resetUrl,
      completeUrl
    }
  })
}

export const useUserResetPassword = async (hash: string, password: string) => {
  return useFetchAuthBasic({
    url: `auth/password/update-by-hash`,
    method: 'put',
    body: {
      hash,
      password,
      grant_type: 'password'
    }
  })
}

export const createAuthorizationHeader = (accessToken: string): Record<'authorization', string> => {
  return {'authorization': `Bearer ${accessToken}`}
}

export const createBasicAuthorizationHeader = (): Record<'authorization', string> => {
  const config: RuntimeConfig = useRuntimeConfig()

  const client_id: string = config.public.CLIENT_ID
  const client_secret: string = config.public.CLIENT_SECRET
  const encodedHeader: string = btoa(`${client_id}:${client_secret}`)
  return {'authorization': `Basic ${encodedHeader}`}
}

export const useUserUpdateSsoEmail = async (id: string, email: string) => {
  return useFetchAuthBasic({
    url: `auth/update-sso-email`,
    method: 'post',
    body: {
      id,
      email
    }
  })
}
