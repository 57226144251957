import type { NuxtApp } from 'nuxt/app'
import type { Log } from '../../log'
import { createConsola } from 'consola/browser'
import createLog from '../../createLog'
import {initDatadogLogs} from '~/modules/log/runtime/loggers/datadog/datadog-logs'
import {initDatadogRum} from '~/modules/log/runtime/loggers/datadog/datadog-rum'

const initClientSpecificLogs = () => {
  // datadog browser logs should be used for critical events, thus it's not added to every error event
  initDatadogLogs();
  // datadog RUM events can be used for each error event
  initDatadogRum();
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  initClientSpecificLogs()

  const log = createLog(createConsola, config.public.C_SPOT_LOG.name, config.public.C_SPOT_LOG.level)

  log.debug('Attached client-side logger')

  addClientSideErrorHandling(log, nuxtApp.vueApp)

  return {
    provide: {
      log
    },
  }
})

function addClientSideErrorHandling(log: Log, app: NuxtApp['vueApp']) {
  app.config.warnHandler = (msg, vm, trace) => {
    log.space('warnHandler').warn(msg, { vm, trace })
  }

  app.config.errorHandler = (error, vm, info) => {
    log
      .space('errorHandler')
      .error(error as Error, { vm, info, component: vm?.$options.__name })
  }
}
