export const USER_STATUS_ACTIVE = 'ACTIVE'
export const USER_STATUS_REPORTED = 'REPORTED'
export const USER_STATUS_BLOCKED = 'BLOCKED'
export const USER_STATUS_BLOCKED_BY_USER = 'BLOCKED_BY_USER'
export const USER_STATUS_DELETED = 'DELETED'
export const USER_STATUS_INACTIVE = 'INACTIVE'

export const DEFAULT_USER_AVATARS = [
  {imgSrc: `/avatars/avatar1.png`},
  {imgSrc: `/avatars/avatar2.png`},
  {imgSrc: `/avatars/avatar3.png`},
  {imgSrc: `/avatars/avatar4.png`},
  {imgSrc: `/avatars/avatar5.png`},
  {imgSrc: `/avatars/avatar6.png`},
  {imgSrc: `/avatars/avatar7.png`},
  {imgSrc: `/avatars/avatar8.png`}
]

export const DEFAULT_AVATAR_URL = `/default-avatar.svg`

export const UNSUBSCRIBE_REASON_NEVER_SIGNED_UP = 'NEVER_SIGNED_UP'
export const UNSUBSCRIBE_REASON_CONTENT_NOT_EXPECTED = 'CONTENT_NOT_EXPECTED'
export const UNSUBSCRIBE_REASON_CONTENT_NOT_RELEVANT = 'CONTENT_NOT_RELEVANT'
export const UNSUBSCRIBE_REASON_TOO_FREQUENTLY = 'TOO_FREQUENTLY'
export const UNSUBSCRIBE_REASON_OTHERS = 'OTHERS'

export const USER_REGISTRATION_DEVICE_MOBILE = 'web-mobile'
export const USER_REGISTRATION_DEVICE_TABLET = 'web-tablet'
export const USER_REGISTRATION_DEVICE_DESKTOP = 'web-desktop'

export const SSO_REGISTRATION_METHOD_GITHUB = 'GITHUB'
export const SSO_REGISTRATION_METHOD_TWITTER = 'TWITTER'
export const SSO_REGISTRATION_METHOD_APPLE = 'APPLE'
