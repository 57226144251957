<template>
  <NuxtLayout name="default">
    <ErrorDescription
      v-if="error?.statusCode === 404"
      :buttonText="$t('404.backButton')"
      :description="$t('404.description')"
      :handleError="handleError"
      :title="$t('404.title')"
      imgSrc="/404.svg"
    />
    <ErrorDescription
      v-if="error?.statusCode === 403"
      :buttonText="$t('403.backButton')"
      :description="$t('403.description')"
      :handleError="handleError"
      :title="$t('403.title')"
      imgSrc="/403.svg"
    />
    <ErrorDescription
      v-if="error?.statusCode !== 403 && error?.statusCode !== 404"
      :buttonText="$t('common.errorGoHome')"
      :description="$t('common.error')"
      :handleError="handleError"
      :title="$t('common.errorTitle')"
      imgSrc="/error.svg"
    />
  </NuxtLayout>
</template>

<script lang="ts" setup>
defineProps({
  error: Object
})

const handleError = () => clearError({redirect: '/'})
</script>
