export const SELECTED_OPTION_ALL_ACTIVITIES = 'ALL_ACTIVITIES'

export const SELECTED_OPTION_BOOKMARKS = 'BOOKMARKS'

export const SELECTED_OPTION_YOUR_POSTS = 'YOUR_POSTS'

export const SELECTED_OPTION_YOUR_ARTICLES = 'YOUR_ARTICLES'

export const SELECTED_OPTION_YOUR_COMMUNITIES = 'YOUR_COMMUNITIES'

export const SELECTED_OPTION_YOUR_EVENTS = 'YOUR_EVENTS'

export const SELECTED_OPTION_YOUR_PROJECTS = 'YOUR_PROJECTS'

export const SELECTED_OPTION_YOUR_TOKENS = 'YOUR_TOKENS'

export const SELECTED_OPTION_BLOCKED_USERS = 'BLOCKED_USERS'

export const SELECTED_OPTION_SETTINGS = 'SETTINGS'

export const USER_TYPE_PROJECT_REPRESENTATIVE = 'TYPE_PROJECT_REPRESENTATIVE'

export const EXISTING_USER_TYPES = [
  {
    id: USER_TYPE_PROJECT_REPRESENTATIVE,
    title: 'createUser.step3ProjectRepresentativeTitle',
    text: 'createUser.step3ProjectRepresentativeDescription',
    image: '/role-representative.svg'
  },
  {
    id: 'TYPE_DEVELOPER',
    title: 'createUser.step3DeveloperTitle',
    text: 'createUser.step3DeveloperDescription',
    image: '/role-developer.svg'
  },
  {
    id: 'TYPE_CREATOR',
    title: 'createUser.step3CreatorTitle',
    text: 'createUser.step3CreatorDescription',
    image: '/role-creator.svg'
  },
  {
    id: 'TYPE_INVESTOR',
    title: 'createUser.step3InvestorTitle',
    text: 'createUser.step3InvestorDescription',
    image: '/role-investor.svg'
  },
  {
    id: 'TYPE_ENTHUSIAST',
    title: 'createUser.step3EnthusiastTitle',
    text: 'createUser.step3EnthusiastDescription',
    image: '/role-enthusiast.svg'
  }
]

export const USER_ROLE_USER = 'ROLE_USER'
export const USER_ROLE_ADMIN = 'ROLE_ADMIN'
export const USER_ROLE_PUBLISHER = 'ROLE_PUBLISHER'
