import type {LogHandler, LogLevel, LogOptions, LogEntry} from '~/types/log'

/**
 * This log provides a way to log messages to a custom log handler (in a serial manner).
 * If no handler is provided, it will log to the console.
 */
export class Log {
  static default = new this({
    space: 'global',
    handler: consoleLogHandler,
  })

  _handler?: LogHandler
  _space: string
  _type = 'log'

  constructor(options?: LogOptions) {
    this._handler = options?.handler || Log.default._handler
    this._space = options?.space || Log.default._space
  }

  debug(message: string, data?: any) {
    this.write('debug', message, data)
  }

  info(message: string, data?: any) {
    this.write('info', message, data)
  }

  warn(message: string, data?: any) {
    this.write('warn', message, data)
  }

  error(message: string | Error, data?: any) {
    const error = message instanceof Error ? message : null

    if (error) {
      data = {
        error: message,
        ...data,
      }
    }
    this.write('error', error?.message ?? (message as string), data)
  }

  write(level: LogLevel, message: string, data?: any) {
    if (typeof data !== 'undefined') {
      data = typeof data !== 'object' ? { data } : data
    }

    if (this._handler) {
      this._handler({ level, message, data, space: this._space })
    } else {
      const logFunc = console[level] || console.log

      logFunc.apply(console, data ? [message, data] : [message])
    }
  }

  /**
   * Create a new sub-space.
   * All spaces are being separated by a dot. E.g. `global.api` and `global.api.getUser`
   * @param subSpace The name of the sub-space in camel-case (e.g. `api` and `api.getUser`)
   */
  space(subSpace: string) {
    return new Log({
      space: `${this._space}.${subSpace}`,
      handler: this._handler,
    })
  }

  /**
   * Measure the time of a function.
   */
  async time(message: string, func: () => any) {
    const start = Date.now()
    const result = await func()
    const end = Date.now()

    this.debug(`${message} took ${end - start}ms`)

    return result
  }
}
function consoleLogHandler({ level, message, data }: LogEntry) {
  const logFunc = console[level] || console.log

  logFunc.apply(console, data ? [message, data] : [message])
}

